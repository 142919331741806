/* Variables CSS */
:root {
  --main-color: #550b7d;
  --main-color-transparent: #550b7d4b;
  --secondary-color: #300b7d;
  --text-color: #ffffff;
  --highlight-color: #ff4ff3;
  --button-bg: #35477d;
  --button-hover-bg: #0056b3;
  --border-color: #969393;
  --focus-color: #007bff;
  --transition-speed: 0.3s;
}

/* Reset de estilos básicos */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

/* Estilos globales */
body {
  display: grid;
  grid-template-areas:
    "Banner"
    "HW"
    "Ch"
    "SC"
    "Footer";
  grid-template-rows: minmax(46.875rem, auto) auto auto auto auto;
  grid-template-columns: 1fr;
  gap: 1rem;
  color: var(--text-color);
  background-image: linear-gradient(180deg, var(--main-color) 40%, var(--secondary-color) 100%);
}

/* Estilos del encabezado (Header) */
#Header {
  color: var(--text-color);
  padding: 0.625rem 0;
  text-align: center;
  width: 100%;
  position: fixed;
  top: -3.75rem;
  left: 0;
  z-index: 1000;
  transition: top var(--transition-speed) ease-in-out;
}

#Header.show {
  top: 0;
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.25rem;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 1rem;
}

nav ul li {
  margin: 0 1.25rem;
}

nav ul li a {
  color: var(--text-color);
  text-decoration: none;
  transition: color var(--transition-speed);
}

nav ul li a:hover {
  color: var(--highlight-color);
}


/* Botón de alternar navegación */
#navToggle {
  display: none; /* Ocultamos el botón por defecto */
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  cursor: pointer;
  z-index: 1001; /* Asegúrate de que esté encima de la navegación */
  background-color: var(--main-color);
  border: none;
  border-radius: 0.25rem;
  padding:  1rem;
  transition: background-color var(--transition-speed);
}

#navToggle:hover {
  background-color: var(--button-hover-bg);
}

#navToggle span {
  display: block;
  width: 1.5rem;
  height: 0.15rem;
  background-color: var(--text-color);
  position: relative;
}

#navToggle span::before,
#navToggle span::after {
  content: '';
  display: block;
  width: 1.5rem;
  height: 0.15rem;
  background-color: var(--text-color);
  position: absolute;
  left: 0;
  transition: all var(--transition-speed);
}

#navToggle span::before {
  top: -0.4rem;
}

#navToggle span::after {
  top: 0.4rem;
}

/* Mostrar la "X" cuando el menú está abierto */
#navToggleCheckbox:checked ~ #navToggle span {
  background-color: transparent;
}

#navToggleCheckbox:checked ~ #navToggle span::before {
  transform: rotate(45deg);
  top: 0;
}

#navToggleCheckbox:checked ~ #navToggle span::after {
  transform: rotate(-45deg);
  top: 0;
}


/* Checkbox escondido */
#navToggleCheckbox {
  display: none; /* Ocultar el checkbox por defecto */
}

/* Mostrar el menú en base al checkbox */
#navToggleCheckbox:checked ~ nav {
  transform: translateX(0);
}

/* Estilos del banner */
#Banner {
  grid-area: Banner;
  color: var(--text-color);
  text-align: center;
  background-image: url(./Img/banner-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 46.875rem;
  margin-bottom: 1.875rem;
}

/* Cómo funciona (HW) */
.HW {
  grid-area: HW;
  padding: 1.25rem 3.125rem;
  margin: 0 1.25rem;
}

.HW h2 {
  font-size: clamp(1.5rem, 5vw, 4rem);
  text-transform: uppercase;
}

.steps {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
}

.step {
  flex: 1 1 15rem;
  border-radius: 1rem;
  min-height: 18.75rem;
  display: flex;
  flex-direction: column-reverse;
  margin: 0.625rem;
}

.text_step {
  background-color: rgba(34, 51, 68, 0.541);
  padding: 1.25rem;
  border-radius: 0 0 2rem 2rem;
  height: 60%;
}

.text_step h3 {
  font-size: clamp(1.5rem, 4vw, 1.8rem);
}

.text_step p {
  font-size: calc(0.875rem + 0.5vw);
  padding: 0.625rem;
}

.step_1 {
  background: url(Img/Step-1.jpg) no-repeat center center/cover;
}

.step_2 {
  background: url(Img/Step-2.jpg) no-repeat center center/cover;
}

.step_3 {
  background: url(Img/Step-3.jpg) no-repeat center center/cover;
}

/* Características (Ch) */
.Ch {
  grid-area: Ch;
  padding: 1.25rem 3.125rem;
  margin: 0 1.25rem;
}

.Ch h2 {
  font-size: clamp(2rem, 5vw, 4rem);
  text-transform: uppercase;
  margin-bottom: 2.25rem;
}

.Ch01 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

#Headset {
  width: max(20.25rem, min(30.25rem, 10.25rem));
  height: max(20.25rem, min(30.25rem, 10.25rem));
}

.features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feature h3 {
  font-size: clamp(1.5rem, 4vw, 2.1875rem);
}

.feature p {
  font-size: calc(1rem + 0.25vw);
}

/* Escenarios y Contenidos (SC) */
.SC {
  grid-area: SC;
  padding: 1.25rem 3.125rem;
  margin: 0 1.25rem;
}

.SC h2 {
  font-size: clamp(2rem, 5vw, 4rem);
  text-transform: uppercase;
}

.scenarios {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
}

.scenario {
  flex: 1 1 18.75rem;
  padding: 0.625rem;
  border-radius: 1rem;
  margin: 0.625rem;
}

.scenario h3 {
  color: var(--highlight-color);
  font-size: clamp(1.5rem, 4vw, 2rem);
}

.scenario img {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  object-fit: cover;
}

/* Footer */
footer {
  grid-area: Footer;
  color: var(--text-color);
  display: flex;
  flex-direction: column; /* Por defecto, el footer está en columna */
  text-align: center;
  position: relative;
  width: 100%;
  transition: max-height var(--transition-speed) ease-in-out, opacity var(--transition-speed) ease-in-out;
  overflow: hidden;
  max-height: 3.75rem; /* Altura inicial del footer */
}

footer.expand {
  max-height: 100vh; /* Altura máxima cuando se expande */
}

footer.expand .extra-info {
  opacity: 1;
}

.extra-info {
  opacity: 0;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  margin: 1.25rem 0 -1.25rem 0;
}

/* Contenedor del mapa */
.map-container {
  position: relative;
  width: 100%;
  max-width: 50rem;
  padding-top: 56.25%;
  overflow: hidden;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Formulario */
.Form_Container {
  background-image: linear-gradient(0deg, var(--main-color) -80%, var(--secondary-color) 170%);
  text-align: left;
  padding: 6.25rem;
}

.Form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  max-width: 37.5rem;
  width: 100%;
}

.Form-group {
  display: flex;
  flex-direction: column;
}

.Form-group label {
  font-weight: bold;
  margin-bottom: 0.3125rem;
}

.Form-group input,
.Form-group textarea {
  width: 100%;
  padding: 0.625rem;
  border: none;
  border-right: var(--border-color) solid;
  border-bottom: var(--border-color) solid;
  background-color: transparent;
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.Form-textarea {
  grid-column: span 2;
}

.Form-submit {
  grid-column: span 2;
  display: flex;
  justify-content: center;
  padding: 0 3.125rem;
}

.Form-submit button {
  background-color: var(--button-bg);
  color: var(--text-color);
  border: none;
  border-radius: 0.25rem;
  padding: 0.625rem 1.25rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color var(--transition-speed);
}

.Form-submit button:hover {
  background-color: var(--button-hover-bg);
}

.Form-group input:focus,
.Form-group textarea:focus {
  border-color: var(--focus-color);
  outline: none;
}

/* Media Query para pantallas pequeñas */
@media (max-width: 920px) {
/* Ocultar el menú por defecto en pantallas pequeñas */
nav {
  transform: translateX(-100%);
  position: fixed; /* Fijar la navegación para que se mueva con el scroll */
  justify-content:start;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px; /* Ajusta el ancho del menú según lo desees */
  background-color: var(--main-color-transparent);
  flex-direction: column; /* Organiza los elementos verticalmente en pantallas pequeñas */
  
}

/* Organiza los enlaces verticalmente en pantallas pequeñas */
nav ul {
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  gap: 1rem;
}

nav ul li {
  margin: 0;
}

#navToggleCheckbox:checked ~ nav ul {
  display: flex;
}

#navToggle {
  display: block; /* Muestra el botón en pantallas pequeñas */
}

/* Para mostrar el menú de navegación al hacer clic */
#navToggleCheckbox:checked ~ nav {
  transform: translateX(0); /* Muestra el menú al hacer clic */
}

  footer {
    flex-direction: column; /* Cambia la dirección de los elementos a columna en pantallas pequeñas */
    max-height: none; /* Elimina la altura máxima en pantallas pequeñas para permitir expansión */
  }

  .extra-info {
    flex-direction: column; /* Organiza el contenido adicional en columna */
    margin: 1.25rem 0; /* Ajusta el margen para pantallas pequeñas */
  }

  .map-container {
    max-width: 100%; /* Asegura que el contenedor del mapa no exceda el ancho del contenedor en pantallas pequeñas */
  }

  .Form_Container {
    padding: 4rem; /* Ajusta el padding del formulario para pantallas pequeñas */
  }
}
